.nav-link {

   
    cursor: pointer;
}


.Toggler {

    cursor: pointer;

}

.machine-name {

    font-weight: 500;
}



.overheight {

    height: 6000px;
    scrollbar-width: none;
}




@media screen and (max-width: 1024px) {



    .machine-name {

        font-weight: 500;
    }
}

@media screen and (max-width: 768px) {
    
    .machine-name {

        font-weight: 500;
    }

}

@media screen and (max-width: 640px) {
    
    .machine-name {

        font-weight: 500;
    }
}



