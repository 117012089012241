
  .homeSwiper {

    width: 100%;
    height: 767px;

  }
 

  




  
  