@tailwind base;
@tailwind components;
@tailwind utilities;

html {

    font-size: 100%;
    scroll-behavior: smooth;
    
}

body {

    font-family: 'Inter';
    scrollbar-width: thin;          /* "auto" or "thin" */
    scrollbar-color: #81d742 transparent;   /* scroll thumb and track */
}

.lp-ul {

    list-style-image: url(https://uploaddeimagens.com.br/images/004/012/874/full/Caminho_278.png?1662492702);
}

  body::-webkit-scrollbar {

    width: 7px;
  }

  body::-webkit-scrollbar-track {
    background: rgba(142, 142, 142, 0.461);        /* color of the tracking area */
  }
  
  body::-webkit-scrollbar-thumb {
    background-color: #81d742;    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
    border: 3px solid #81d742;  /* creates padding around scroll thumb */
    

  }

  .styled-scrollbar-menu::-webkit-scrollbar {

    width: 7px;
  }

  .styled-scrollbar-menu::-webkit-scrollbar-track {
    background: rgba(142, 142, 142, 0.461);        /* color of the tracking area */
  }
  
  .styled-scrollbar-menu::-webkit-scrollbar-thumb {
    background-color: #81d742;    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
    border: 3px solid #81d742;  /* creates padding around scroll thumb */
    

  }
  
  

.active {

    color: #81D742;
}

.carouselE {

    scrollbar-width: 1px!important;
    scrollbar-width: none;
    
}

.carouselE::-webkit-scrollbar {

    display: none;
    width: 1px;
    display: none;
    width: 1px;
    height: 1px;
}

.scroll-text::-webkit-scrollbar
{

    background-color: transparent;
    width: 5px;
}

.scroll-text::-webkit-scrollbar-thumb {

    background-color: #81D742;
    -webkit-box-shadow: inset 1px 1px 0 rgba(0,0,0,0.10),inset 0 -1px 0 rgba(0,0,0,0.07);
}

.scroll-text {

    scrollbar-color: #81D742;
    scrollbar-track-color: #81D742;
}



.container {

    max-width: 75%;
}

.form-shadow {

    -webkit-box-shadow: 0px 0px 50px 10px rgba(80,80,80,0.2); 
    box-shadow: 0px 0px 50px 10px rgba(80,80,80,0.2);
}

.carousel-linhadotempo {

    scrollbar-width: none;
}

.carousel-linhadotempo::-webkit-scrollbar {

    display: none;
    width: 1px;
    height: 1px;
}

.lpbutton {

    background: linear-gradient(217deg, rgba(129,215,66,1) 0%, rgba(129,215,66,1) 19%, rgba(65,108,33,1) 100%);
    background-size: 100%;
    transition: 1s;
}

.lpbutton:hover {

    background-size: 1000px;

}

.lpshadow {

    -webkit-box-shadow: 0px 0px 50px 0px rgba(129,215,66,0.2); 
box-shadow: 0px 0px 50px 0px rgba(129,215,66,0.2);
}

.num-shadow {

    text-shadow: 0px 0px 20px rgba(129,215,66,0.5);
}

.bg-gradient {

    background: transparent linear-gradient(90deg, #2C2D2E 0%, #202124 100%) 0% 0% no-repeat padding-box;
}

.bg-gradient-180deg {

    background: transparent linear-gradient(180deg, #2C2D2E 0%, #202124 100%) 0% 0% no-repeat padding-box;
}

.bg-ground {

    background-image: url('https://uploaddeimagens.com.br/images/003/913/339/full/Grupo_883.png?1655992584');
}

.swiper-pagination-bullet {
    width: var(--swiper-pagination-bullet-width,var(--swiper-pagination-bullet-size,8px));
    height: var(--swiper-pagination-bullet-height,var(--swiper-pagination-bullet-size,8px));
    display: inline-block;
    border-radius: 50%;
    background: var(--swiper-pagination-bullet-inactive-color, #81D742)!important;
    opacity: var(--swiper-pagination-bullet-inactive-opacity, .6);
  }

  .swiper-pagination-bullet-active {

    opacity: 1;
  }

  .styled-scroll {

    padding-right: 20px;
    padding-left: 30px;
  }

  .styled-scroll::-webkit-scrollbar {

    width: 5px;
  }

  .styled-scroll::-webkit-scrollbar-track {
    background: rgba(142, 142, 142, 0.461);        /* color of the tracking area */
  }
  
  .styled-scroll::-webkit-scrollbar-thumb {
    background-color: blue;    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
    border: 3px solid #81d742;  /* creates padding around scroll thumb */
  }

  .styled-scroll {

    scrollbar-width: thin;          /* "auto" or "thin" */
    scrollbar-color: #81d742 transparent;   /* scroll thumb and track */
  }



@media screen and (max-width: 1558px) {

    html {

        font-size: 90%;
    }

    .container {

        max-width: 85%;
    }
}

@media screen and (max-width: 1280px) {

    html {

        font-size: 85%;
    }
}

@media screen and (max-width: 1024px) {

    html {

        font-size: 80%;
    }
}

@media screen and (max-width: 640px) {

    html {

        font-size: 75%;
    }
}

